<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Coupon hinzufügen</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <form @submit="create_coupon" class="form" method="post">
            <div class="form-wrap">
              <label for="coupon_code">Code</label>
              <input v-model="coupon.coupon_code" class="form-input" type="text" name="coupon_code" id="coupon_code" />
            </div>

            <div class="form-wrap">
              <label for="coupon_amount">Betrag</label>
              <input v-model="coupon.coupon_amount" class="form-input" type="text" name="coupon_amount" id="coupon_amount" />
            </div>

            <div v-if="errors.length > 0">
              {{ errors }}

              {{ coupon }}
            </div>

            <p style="margin-bottom: 0">
              <input type="submit" value="Coupon erstellen ›" class="button button-ci button-round button-100" />
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "dashboard",
  components: {
    AdminNav,
  },
  data() {
    return {
      coupon: {},
      errors: [],
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/coupons", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.coupons = response.data.coupons;
        });
    },
    create_coupon: function (e) {
      e.preventDefault();

      axios
        .post(process.env.VUE_APP_BASE_API + "/admin/coupons/", this.coupon, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.$router.push("/admin/coupons/");
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss">
.pending-wrap {
  border: 1px solid #444;
  margin-bottom: 20px;

  h3 {
    width: 100%;
    padding: 14px;
    margin: 0;
    background: #444;
    color: #fff;
  }
}
</style>
